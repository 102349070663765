import { Paper, Stack, Typography } from '@mui/material';
import React from 'react'

type SectionProps = {
    children?: React.ReactNode;
    header?: string;
}
export function SectionForm(
    {children, header}: SectionProps
) {
  return (
    <Paper sx={{p: 2, flex: 1, alignSelf: 'stretch'}}>
      <Stack direction={"column"} spacing={3}>
        {
          header ? (
            <Typography variant="h6">{header}</Typography>
          ) : null
        }

        {children}
      </Stack>
    </Paper>
  )
}