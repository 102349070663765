import { Alert, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { AppState } from 'index';
import { generateRithm, generateSynth } from 'api/generations.api';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { LoadStatus } from 'utils/hooks/enums/LoadStatus';
import { Router } from 'utils/routes/routes';
import useAuth from 'hooks/useAuth';
import {TagSetIn, TagSynthSettings} from 'types/tags.types';
import { GeneratedNames } from 'types/result.types';


interface ButtonGenerateProps {
    variant: "bass" | "synth"
}

export function ButtonGenerate(props: ButtonGenerateProps) {

    const state = useContext(AppState);
    const {auth, setAuth} = useAuth();

    const [resultId, setResultId] = React.useState<string>("");
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [errorMessage, setErrorMesage] = React.useState("");
    const [isError, setIsError] = React.useState(false);

    const handleGenerateStart = async () => {
        setIsLoaded(true);
        setIsError(false);
        state.generatedNames.value = {
            result_id: '',
            midi_files: []
        };


        let response: string | GeneratedNames = "";

        if (props.variant === "bass") {
            const params: TagSetIn = {
                key: state.selectedTagSetSettings.value.key,
                bpm: state.selectedTagSetSettings.value.bpm === 0 ? 120 : state.selectedTagSetSettings.value.bpm,
                genre_id: state.selectedTagSetSettings.value.genre_id,
                preset: state.bass_presets.value.find(tag => tag.id === state.selectedTagSetSettings.value.preset)?.name.toLowerCase() || "complex_bass"
            }
            const synth_preset = state.synth_presets.value.find(tag => tag.id === state.selectedTagSynthSettings.value.preset)?.name || null;
            response = await generateRithm(params, synth_preset, auth, state.selectedSf.value);
        } else {
            const presetName = state.synth_presets.value.find(tag => tag.id === state.selectedTagSynthSettings.value.preset)?.name || null;
            response = await generateSynth(presetName, auth);
        }

        if (typeof response !== 'string') {
            // state.generatedNames.value = response;
            props.variant === "bass" ? state.generatedNames.value = response : state.generatedSynthPath.value = response;
            setResultId(response.result_id);
            try {
                if (response.midi_files.length === 0) {
                    setIsError(true);
                    setErrorMesage('Генерация не удалась. Попробуйте еще раз.');
                }
            } catch {

            }
        } else {
            setErrorMesage(response);
            setIsError(true);
        }
        setIsLoaded(false);
    }

    return (
        <Stack direction={"column"}>
            {/* <Link to={Router.GeneratedExamples}> */}
            <Button 
                disabled={isLoaded}
                variant='contained' 
                title='Сгенерировать' 
                onClick={handleGenerateStart}
            >
                {
                    isLoaded ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CircularProgress/> 
                            <Typography>Loading...</Typography>
                        </Stack>
                    ) : 'Сгенерировать'
                }
            </Button>
            {/* </Link> */}
            {isError ? <Alert severity="error">{errorMessage}</Alert> : null}
        </Stack>
    )
}

