import { TagStr } from "types/tags.types";


export const BASS_PRESETS_DATA: TagStr[] = [
    { id: 1, name: "complex_bass"},
    { id: 2, name: "middle_bass"},
    { id: 3, name: "simple_bass"},
    { id: 4, name: "A.bass"},
    { id: 5, name: "B.bass"},
    { id: 6, name: "C.bass"},
    { id: 7, name: "D.bass"},
    { id: 8, name: "E.bass"},
    { id: 9, name: "X.bass"},
    { id: 10, name: "subbass"},
]

export const keys: TagStr[] = [
    { id: 0, name: "Cmaj"},
    { id: 1, name: "C#maj" },
    { id: 2, name: "Dmaj" },
    { id: 3, name: "D#maj" },
    { id: 4, name: "Emaj" },
    { id: 5, name: "Fmaj" },
    { id: 6, name: "F#maj" },
    { id: 7, name: "Gmaj" },
    { id: 8, name: "G#maj" },
    { id: 9, name: "Amaj" },
    { id: 10, name: "A#maj" },
    { id: 11, name: "Hmaj" }
]

export const SYNTH_PRESETS_DATA: TagStr[] = [
    { id: 1, name: "small_synth_1" },
    { id: 2, name: "small_synth_2" },
    { id: 3, name: "A" },
    { id: 4, name: "B" },
    { id: 5, name: "C" },
    { id: 6, name: "D" },
    { id: 7, name: "E" },
    { id: 8, name: "chromatic_synth_middle"},
    { id: 9, name: "chromatic_synth_complex"},
    { id: 10, name: "chromatic_synth_big"},
    { id: 11, name: "X"},
]
