import { Stack } from '@mui/material';
import { AppState } from 'index';
import { fetchGenres, fetchInstruments, fetchKeyTags, fetchTonalities } from 'api/tags.api';
import React, { useContext, useEffect } from 'react'
import { TagStr } from 'types/tags.types';
import { RithmSection } from 'components/forms/rithmSectionSettings/RithmSection';
import { SynthSection } from 'components/forms/synthSettings/SynthSection';
import { BassSettingsForm } from 'components/forms/bassSettingsForm/BassSettingsForm';

export function SelectPage() {
  const state = useContext(AppState);

  const [genres, setGenres] = React.useState<TagStr[]>([]);

  const fetchAllTags = async () => {
    try {
      const [keys, tonalities, instruments, genres] = await Promise.all([
        fetchKeyTags(),
        fetchTonalities(),
        fetchInstruments(),
        fetchGenres(),
      ]);

      setGenres(genres || []);

      state.keyTags.value = keys || [];
      state.tonalityTags.value = tonalities || [];
      state.instrumentTags.value = instruments || [];
      state.genreTags.value = genres || [];

    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchAllTags();
  }, [])

  return (
    <Stack direction={"row"} sx={{height: "100vh"}} alignItems={"center"} justifyContent="center">
      <Stack direction={"row"} spacing={5} sx={{width: "100%"}} justifyContent="center">
        <RithmSection />
        <Stack direction={"column"} spacing={3}>
          <SynthSection />
          <BassSettingsForm />
        </Stack>
        
      </Stack>
    </Stack>
  )
}
